import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import ProductPage from '@/views/ProductPage.vue'
// import ContactForm from "@/components/base/ContactForm";

const routes = [
  {
    path: '/product/:product',
    name: 'Products',
    component: ProductPage
  },
  // {
  //   path: '/email',
  //   name: 'Email',
  //   component: ContactForm
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'Main',
    component: HomePage
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  if(to.name == 'Main' && to.path != '/'){
    return '/'
  }
  return true
})
export default router
