import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "@/i18n";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(VueReCaptcha, {
    siteKey: '6Le5-HcjAAAAAEPmmmk7otSMI8lJS7GTaMyvKkYZ',
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: false,
        explicitRenderParameters: {
            badge: "bottomleft",
        }
    }
})

import SectionContent from '@/components/base/SectionContent.vue'
app.component('SectionContent', SectionContent)
import HeaderComponent from '@/components/base/HeaderComponent.vue'
app.component('HeaderComponent', HeaderComponent)
import FooterComponent from '@/components/base/FooterComponent.vue'
app.component('FooterComponent', FooterComponent)
import AboutUs from '@/components/base/AboutUs.vue'
app.component('AboutUs', AboutUs)
import HeadSection from '@/components/base/HeadSection.vue'
app.component('HeadSection', HeadSection)
import OurVision from '@/components/base/OurVision.vue'
app.component('OurVision', OurVision)
import OurCulture from '@/components/base/OurCulture.vue'
app.component('OurCulture', OurCulture)
import OurPartners from '@/components/base/OurPartners.vue'
app.component('OurPartners', OurPartners)
import ProductsComponent from '@/components/base/ProductsComponent.vue'
app.component('ProductsComponent', ProductsComponent)
import ContactUs from '@/components/base/ContactUs.vue'
app.component('ContactUs', ContactUs)
import ProductsListSlider from '@/components/base/ProductsListSlider.vue'
app.component('ProductsListSlider', ProductsListSlider)
import InfoSection from '@/components/base/InfoSection.vue'
app.component('InfoSection', InfoSection)
import HeadProductSection from '@/components/base/products/HeadProductSection.vue'
app.component('HeadProductSection', HeadProductSection)
import ProductInfoSections from '@/components/base/products/ProductInfoSections.vue'
app.component('ProductInfoSections', ProductInfoSections)
import AboutProduct from '@/components/base/products/AboutProduct.vue'
app.component('AboutProduct', AboutProduct)
import ProductEventList from '@/components/base/products/ProductEventList.vue'
app.component('ProductEventList', ProductEventList)
import OtherProduct from '@/components/base/products/OtherProduct.vue'
app.component('OtherProduct', OtherProduct)

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
app.use(Toast, {
    "optons": {
        "transition": "Vue-Toastification__bounce",
        "maxToasts": 20,
        "newestOnTop": true,

        "position": "top-right",
        "timeout": 5000,
        "closeOnClick": true,
        "pauseOnFocusLoss": true,
        "pauseOnHover": true,
        "draggable": true,
        "draggablePercent": 0.6,
        "showCloseButtonOnHover": false,
        "hideProgressBar": true,
        "closeButton": "button",
        "icon": true,
        "rtl": false
    }
});

app.mount('#app')
