<template>
    <section class="main">
        <HeadSection :data="head"/>
        <AboutUs :data="aboutUs"/>
        <OurVision :data="ourVision"/>
        <OurCulture :data="ourCulture"/>
        <OurPartners :data="ourPartners"/>
        <ProductsComponent :data="products"/>
        <ProductsListSlider :data="productsList"/>
        <ContactUs :data="contact"/>
        <ContactForm/>
        <InfoSection :data="info"/>
    </section>
</template>

<script>
import ContactForm from "@/components/base/ContactForm";
export default {
    name: 'HomePage',
    data() {
        return {
            jsonFile: ''
        }
    },
    components: {ContactForm},
    computed:{
        head(){
            return this.jsonFile?.head
        },
        aboutUs(){
            return this.jsonFile?.about_us
        },
        ourVision(){
            return this.jsonFile?.our_vision
        },
        ourCulture(){
            return this.jsonFile?.our_culture
        },
        ourPartners(){
            return this.jsonFile?.our_partners
        },
        products(){
            return this.jsonFile?.products
        },
        contact(){
            return this.jsonFile?.contact
        },
        productsList(){
            return this.jsonFile?.products_list
        },
        info(){
            return this.jsonFile?.info
        }
    },
    mounted() {
    },
    created() {
        this.jsonFile = require('../../pages/main-page.json')
    }
}
</script>