<template>
    <footer class="footer">
        <div class="footer-wrap">
            <router-link  to="/" class="footer__logo"><img :src="require('@/assets/footer-logo.png')" alt=""></router-link>
            <div class="footer__info">
                <ul class="footer__list">
                    <li class="footer__item" v-for="(menu,i) in menuList" :key="i">
                        <router-link class="footer__link" :to="menu.url">{{ menu.title[$i18n.locale] }}</router-link>
                    </li>
                </ul>
                <div v-if="false">
                    <button class="button button-live-chat">
                        <img class="button-live-chat__icon" :src="require('@/assets/chat.svg')" alt="chat">
                        {{$t('buttons.Live Chat')}}
                    </button>
                </div>
            </div>
        </div>
    </footer>
    <div class="copyright">
        <div class="footer-wrap copyright__wrap">
            <p class="copyright__text">{{ $t('Copyright.text') }}</p>
            <div class="copyright__right">
                <ul class="list_of_agreements">
                    <li class="list_of_agreements__item"
                        v-for="(item, i) in list_of_agreements"
                        :key="i"
                        :class="item.icon"
                    >
                        <span class="list_of_agreements__item__badge"></span>
                        <a :href="item.url" :target="item.target"> {{ item.text }}</a>
                    </li>
                </ul>
                <ul class="copyright__list">
                    <li class="copyright__item"><a href="https://m.facebook.com/login/?locale2=ru_RU"><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li class="copyright__item"><a href="https://www.instagram.com/login"><i class="fa-brands fa-instagram"></i></a></li>
                    <li class="copyright__item"><a href="https://www.linkedin.com/login"><i class="fa-brands fa-linkedin-in"></i></a></li>
                    <!--                <li class="copyright__item"><a href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoicnUifQ%3D%3D%22%7D"><i class="fa-brands fa-twitter"></i></a></li>-->
                    <!--                <li class="copyright__item"><a href="https://www.youtube.com/"><i class="fa-brands fa-youtube"></i></a></li>-->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
    data(){
        return{
            list_of_agreements: [
                {
                    text: 'Privacy Policy',
                    url: 'https://app.termly.io/document/privacy-policy/db1331f8-4fd5-4b42-8c99-70d89bcc5b37',
                    icon: 'policy',
                    target:"_blank"
                },
                {
                    text: 'Cookies Policy',
                    url: '',
                    icon: 'cookies',
                    target:""
                },
                {
                    text: 'Terms & Conditions',
                    url: '',
                    icon: 'terms',
                    target:""
                }
            ],
            menuList:[
                {
                    title: {
                        en: "Pro Hub",
                        es: "Pro Hub",
                        pt: "Pro Hub",
                    },
                    url:"/product/hub"
                },
                {
                    title: {
                        en: "Pro Vision",
                        es: "Pro Vision",
                        pt: "Pro Vision",
                    },
                    url:"/product/vision"
                },
                {
                    title: {
                        en: "Pro ELD",
                        es: "Pro ELD",
                        pt: "Pro ELD",
                    },
                    url:"/product/eld"
                },
                {
                    title: {
                        en: "Pro Cool",
                        es: "Pro Cool",
                        pt: "Pro Cool",
                    },
                    url:"/product/cool"
                },
                {
                    title: {
                        en: "Pro Track",
                        es: "Pro Track",
                        pt: "Pro Track",
                    },
                    url:"/product/track"
                },
                // {
                //     title: {
                //         en: "Privacy Policy",
                //         es: "Privacy Policy",
                //         pt: "Privacy Policy",
                //     },
                //     url:"/privacy-policy"
                // }
            ],
        }
    }
}
</script>

<style scoped>

</style>