<template>
     <router-view v-slot="{ Component, route }">
         <transition name="moveUp">
             <component :is="Component" :key="route.path"/>
         </transition>
     </router-view>
</template>

<script>
export default {
    name: "SectionContent"
}
</script>

<style lang="css">
.moveUp-enter-active {
    animation: fadeIn 1s ease-in;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    50%{opacity: 0.5;}
    100%{opacity: 1;}
}
@keyframes moveUp {
    0%{ transform: translateY(0); }
    100%{ transform: translateY(-400px); }
}
</style>