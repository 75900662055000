<template>
    <header>
        <div class="wrap">
            <div class="header">
                <router-link to="/" class="header__logo">
                    <img :src="require(`@/assets/logo.png`)" alt="logo">
                </router-link>
                <div class="header__wrap" :class="{'header__menu-open': showMenu}">
                    <div class="mobile-menu-header mobile-menu p-lr-40">
                        <div class="mobile-menu__title">{{ $t('header-menu.Menu') }}</div>
                        <div class="mobile-menu__button-close">
                            <button class="button" @click="openMenu">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2.82007" y="0.20929" width="24" height="3.69231" transform="rotate(45 2.82007 0.20929)" fill="currentColor"/>
                                    <rect y="16.9706" width="24" height="3.69231" transform="rotate(-45 0 16.9706)" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <nav class="nav p-lr-40">
                        <ul class="nav__list">
                            <template v-for="menu in menuList" :key="menu.title" >
                                <li class="nav__item" :class="[{'nav__item--active': menu.title == routeName},  !mobileMedia && 'nav__item-hover']">
                                    <router-link class="nav__link" :to="menu.url">{{ menu.title }}</router-link>
                                    <template v-if="menu.listMenu">
                                        <span class="badge"></span>
                                        <ul class="sub-menu-list">
                                            <li class="sub-menu-list__item" v-for="subMenu in menu.listMenu" :key="subMenu.title" :class="{'sub-menu-list__item--active': subMenu.url == routePath}">
                                                <router-link class="nav__link" :to="subMenu.url">{{ subMenu.title }}</router-link>
                                            </li>
                                        </ul>
                                    </template>
                                </li>
                            </template>
                            <li class="nav__item" :class="[{'nav__item--active': 'Contact' == routeName }, !mobileMedia ? 'nav__item-hover': 'nav__item-block']">
                                <button class="button nav__link" @click="contact">Contact</button>
                            </li>
                            <li class="nav__item search-container" v-if="false">
                                <button class="button search-button" @click="openSearch">
                                    <svg width="29" height="29" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.8283 18.1257C21.4933 18.7854 22.1583 19.4398 22.8127 20.0941C24.68 21.9615 26.5527 23.8289 28.4148 25.7015C29.4947 26.7868 29.0106 28.5744 27.5369 28.9362C26.8187 29.1117 26.1963 28.9096 25.6696 28.3829C23.8288 26.5368 21.9827 24.696 20.142 22.8553C19.4716 22.1849 18.8013 21.5146 18.1256 20.839C15.2528 22.7808 12.1299 23.4192 8.76223 22.6212C5.9532 21.9509 3.71343 20.3921 2.05355 18.0299C-1.25025 13.3323 -0.500111 6.82042 3.77727 2.96333C8.08657 -0.925682 14.609 -0.989524 18.9981 2.81969C23.3872 6.61826 24.2864 13.178 20.8283 18.1257ZM2.4366 11.4755C2.44724 16.4658 6.50117 20.5144 11.4915 20.5091C16.4658 20.4985 20.5144 16.4445 20.5091 11.4489C20.5037 6.48525 16.4338 2.43132 11.4595 2.44196C6.49053 2.44728 2.42596 6.51185 2.4366 11.4755Z" fill="currentColor"/>
                                    </svg>
                                </button>
                                <div class="search-input" :class="{'search-input-open' : searchControl}">
                                    <label class="search-input__label">
                                        <input class="search-input__input" type="text" placeholder="I’m searching...">
                                    </label>
                                    <button class="button search-input__button">
                                        <svg width="9" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L1 13" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </li>
                            <li class="nav__item select-container" v-if="false">
                                <div class="select-wrap">
                                    <select class="nav__link select" name="lang" v-model="lag">
                                        <option value="en">Eng</option>
                                        <option value="es">Esp</option>
                                        <option value="pt">Por</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="menu-hamburger" @click="openMenu" v-if="mobileMedia">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderComponent",
    data(){
        return{
            showMenu: false,
            searchControl: false,
            mobileMedia: false,
            menuList:[
                {
                    title: 'Main',
                    url: '/',
                },
                {
                    title: 'Products',
                    url: '',
                    listMenu:[
                        {
                            title: 'Pro Hub',
                            url: '/product/hub'
                        },
                        {
                            title: 'Pro Vision',
                            url: '/product/vision'
                        },
                        {
                            title: 'Pro ELD',
                            url: '/product/eld'
                        },
                        {
                            title: 'Pro Cool',
                            url: '/product/cool'
                        },
                        {
                            title: 'Pro Track',
                            url: '/product/track'
                        },
                    ]
                },
                // {
                //     title: 'Contacts',
                //     url: '#'
                // }
            ],
        }
    },
    computed:{
        lag:{
            get(){return  this.$i18n.locale},
            set(val){
                this.$i18n.locale = val
                localStorage.setItem('lang',val)
            }
        },
        routePath(){
            return this.$route.path
        },
        routeName(){
            return this.$route.name
        }
    },
    methods:{
        openMenu(){
            this.showMenu = !this.showMenu
        },
        resizeWindow(){
            this.mobileMedia = (window.innerWidth < 850) ? true : false
        },
        openSearch(){
            this.searchControl = !this.searchControl
        },
        contact(){
            document.querySelector('#contact').scrollIntoView({ behavior : "smooth" , block: "start"})
        }
    },
    mounted() {
        this.resizeWindow()
        window.addEventListener('resize',this.resizeWindow)
    }
}
</script>

<style scoped>

</style>